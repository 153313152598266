<template>
  <common-page-edit
    :title="title"
    :nameRoleEntity="nameRoleEntity"
    :apiNameGet="apiNameGet"
    :apiNameCreate="apiNameCreate"
    :apiNameUpdate="apiNameUpdate"
    routeNameShow="Showpersoncodevalue"
    routeNameCreate="Createpersoncodevalue"
    :keyRoute="'CodeId'"
    :backUrl="pathBack"
    @set-loadData="setLoadData"
    @set-sheme="setSheme"
    :readDataFromParent="true"
    :loadDataIn="loadData"
    :schemeIn="scheme"
    :convertLoadData="convertLoadData"
    :paramsSave="paramsSave"
    :isDefaultConverter="false"
    :paramsSend="paramsSend"
    :loadIn="load"
    :controlLoad="true"
    @set-load="setLoad"
  >
  </common-page-edit>
</template>
<script>
import { NAME_OBJECT } from "../../../../RoleConstanans";
import CommonPageEdit from "../../commonElement/CommonPageEdit.vue";
import convertFieldInfoListToScheme from "../../../../helpers/converters/convetFieldInfoListToScheme";
import { API_METHODS } from "../../../../constant";
import { ApiService } from "../../../../util/ApiService";
import getSchemeComponet from "../../../../helpers/generatorForm/getSchemeComponet";
export default {
  name: "EditPagePersoneCodeValue",
  components: {
    CommonPageEdit
  },
  data() {
    return {
      routeField: "CodeId",
      wait: false,
      scheme: {},
      loadData: null,
      fieldInfoList: null,
      preResult: null,
      load: false,

      routeNameShow: "Showpersoncodevalue",
      routeNameCreate: "Createpersoncodevalue",
      pathBack: `/contract/${this.$route.params.ContractId}/person/${this.$route.params.PersonId}/edit`,
      apiNameGet: API_METHODS.GET_PERSONE_CODE_VALUE,
      apiNameCreate: API_METHODS.CREATE_PERSONE_CODE_VALUE,
      apiNameUpdate: API_METHODS.UPDATE_PERSONE_CODE_VALUE,
      loadData: null,

      oldLoadData: null,

      contactList: [],
      controlLoad: false,
      templateValcodeId: null,
    };
  },
  computed: {
    title() {
      let text1 = this.$t("Просмотр кода персоны");
      let text2 = this.$t("Создание кода персоны");
      let text3 = this.$t("Редактирование кода персоны");

      let findShow = this.$route.path.indexOf("show");
      if (findShow != -1) {
        return text1;
      }
      let findCreate = this.$route.path.indexOf("create");
      if (findCreate != -1) {
        return text2;
      }
      let findEdit = this.$route.path.indexOf("edit");
      if (findEdit != -1) {
        return text3;
      }
      return text1;
    },
    nameRoleEntity() {
      return NAME_OBJECT.personCodeValue;
    },
    paramsSend() {
      return {
        id: this.$route.params[this.routeField] || "0",
        PersonId: this.$route.params.PersonId
      };
    }
  },

  watch: {
    loadData: {
      handler: function(val, oldVal) {
        if (this.templateValcodeId !== val.codeId) {
          this.loadNewValue();
        }
        this.templateValcodeId = val.codeId;
      },
      deep: true
    }
  },
  methods: {
    convertLoadData(res) {
      let loadData = res.object;
      let fieldInfoList = res.fieldInfoList;
      let scheme = { configForm: [] };
      this.templateValcodeId = res.object.codeId;
      scheme.configForm = convertFieldInfoListToScheme({
        fieldInfoList,
        hasLang: false
      });
      
      return {
        loadData,
        fieldInfoList,
        scheme
      };
    },
    setLoadData(loadData) {
      this.loadData = loadData;
    },
    setSheme(scheme) {
      this.scheme = scheme;
    },
    setLoad(load) {
      this.load = load;
    },
    paramsSave() {
      return {
        id: this.$route.params.ContactId,
        SubjectId: this.$route.params.PersonId
      };
    },

    async loadNewValue() {
      let id = this.loadData.codeId;
      const res = await ApiService(this.apiNameGet, {
        id: id,
        PersonId: this.$route.params.PersonId,
        self: this
      });

      this.loadData.codeCompanyId = res.object.codeCompanyId;
      this.loadData.codeEnumId = null;
      this.loadData.value = null;
      let configForm = {
        code: "value",
        type: "String",
        name: "Значение",
        sizePercent: 40,
        refEntityCode: null,
        isLang: false,
        isReadOnlyAdd: false,
        isReadOnlyEdit: false,
        colWidth: 4,
        required: false,
        subListId: null
      };
      let isEnum = res.object.isEnum;
      if (isEnum == true) {
        configForm.subListId = id;
        configForm.refEntityCode = "CodeEnum";
        configForm.type = "refEntity";
        configForm.code = "codeEnumId";
      }

      let fullSchema = getSchemeComponet(configForm.type, configForm);
      this.$delete(this.scheme.configForm, 1);
      this.scheme.configForm.push(fullSchema);
    }
  }
};
</script>
